<template>
  <a-modal
    :width="500"
    :closable="false"
    :mask-closable="false"
    :keyboard="false"
    :visible="visible"
    title="Sync Product Blibli berdasarkan SKU"
    ok-text="Sync"
    @cancel="onCancel"
    @ok="submitModalSyncProductBlibliBySKU"
  >
    <div>
      <a-select
        v-model="selectedSKU"
        allow-clear
        show-search
        placeholder="Masukkan SKU produk"
        class="mb-4 w-100"
        :filter-option="false"
        :not-found-content="loadingSearch ? undefined : null"
        @search="fetchProductIgnoreCommitState"
        @change="onChangeSKU"
      >
        <a-select-option v-for="item in listProductChannel" :key="item.id" :value="item.blibli_sku">
          <div class="d-flex flex-column product-select w-100">
            <div><b>{{ item?.title }}</b></div>
            <div class="mt-2 mb-1">
              {{ 'SKU Powerbiz: ' }}<a-tag color="red">
                {{ item?.master_sku || item?.seller_sku }}
              </a-tag>
            </div>
            <div>
              {{ 'SKU Blibli: ' }}<a-tag color="blue">
                {{ item?.blibli_sku }}
              </a-tag>
            </div>
          </div>
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSKU: undefined,
      listProductChannel: [],
      loadingSearch: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
  },
  watch: {
    visible: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val) {
          this.selectedSKU = undefined
          this.listProductChannel = []
        }
      },
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    submitModalSyncProductBlibliBySKU() {
      const splitedSKU = this.selectedSKU.split('-')
      splitedSKU.pop()
      const payload = {
        product_sku: splitedSKU.join('-'),
      }
      this.$emit('ok', payload)
    },
    onChangeSKU(e) {
      if (!e) {
        this.listProductChannel = []
      }
    },
    async fetchProductIgnoreCommitState(e) {
      if (e) {
        this.controllerList = new AbortController()
        const payload = {
          signal: this.controllerList.signal,
          business_id: this.businessId,
          channelId: this.$route.params.id,
          channel: 'blibli',
          params: {
            q: e,
            page: 1,
            limit: 99,
          },
        }
        return await this.$store.dispatch('productChannel/GETPRODUCTNOTCOMMITSTATE', payload)
        .then(response => {
          if (response.length === 0) {
            this.listProductChannel = []
          } else {
            let tempListCatalog = []
            response.forEach(item => {
              item.catalogs.forEach(catalog => {
                tempListCatalog.push({
                  ...catalog,
                })
              })
            })
            this.listProductChannel = tempListCatalog
          }
        })
        .catch(() => {})
        .finally(() => {
          this.controllerList = null
          this.loadingSearch = false
        })
      } else {
        this.listProductChannel = []
      }
    },
  },
}
</script>

<style lang="scss">
.group-product-select .ant-select-dropdown-menu-item-group-title {
  height: fit-content !important;
  line-height: normal !important;
  border-top: 1px solid rgba(0, 0, 0, 0.45);
  padding-top: 10px;
}
</style>
<style lang="scss" scoped>
.ant-select-lg .ant-select-selection--single .product-select {
  white-space: nowrap !important;
}
.ant-select-selection-selected-value .product-select {
  white-space: nowrap !important;
}
.product-select {
  white-space: pre-wrap !important;
  .sku-desc {
    font-size: 12px;
    font-weight: 500;
  }
}

.ant-input-number-handler-wrap .remove-arrow {
  width: 0px !important;
}
</style>
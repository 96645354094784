<template>
  <a-modal
    centered
    :width="450"
    :footer="null"
    :closable="!downloading && !uploading"
    :mask-closable="false"
    :keyboard="false"
    :visible="visible"
    :title="$t('product.import_change_price')"
    @cancel="onCancel"
  >
    <a-alert
      type="info"
      class="pca-alert-info mb-3"
      show-icon
    >
      <template #message>
        <span>Anda dapat mengimpor 10.000 data sekaligus (format: xlsx,xls) untuk memperbarui Harga secara massal.</span>
      </template>
    </a-alert>
    <div class="row">
      <div class="col-md-6">
        <div
          class="d-flex flex-column justify-content-center h-100 py-5 px-3 rounded font-weight-bold text-center cpoin boxImportExport"
          :class="downloading ? 'pointer-no':''"
          @click="onDownloadFile"
        >
          <a-icon :type="downloading ? 'loading':'download'" class="text-danger h2 mb-1" />
          <div>Download Template</div>
          <small v-if="selectedProducts.length">
            {{ selectedProducts.length + ' row dipilih' }}
          </small>
        </div>
      </div>
      <div class="col-md-6">
        <label
          class="d-flex flex-column justify-content-center h-100 py-5 px-3 rounded font-weight-bold text-center mb-0 cpoin boxImportExport"
          :class="uploading ? 'pointer-no':''"
        >
          <input
            ref="fileImportEksporRef"
            type="file"
            hidden
            accept=".xls,.xlsx"
            :disabled="uploading"
            @change="changeFile"
          />
          <a-icon :type="uploading ? 'loading':'upload'" class="text-danger h2 mb-1" />
          <div>Upload Template</div>
          <small v-if="fileData.name">
            <a-icon type="file-excel" /> {{ fileData.name }}
          </small>
        </label>
      </div>
    </div>
    <div class="mt-3 text-right">
      <a-button
        class="mr-3"
        type="primary"
        size="large"
        ghost
        :disabled="downloading || uploading"
        @click="onCancel"
      >
        {{ $t('product.cancel') }}
      </a-button>
      <a-button
        type="primary"
        size="large"
        :disabled="!fileData.name || downloading"
        :loading="uploading"
        @click="onUploadFile"
      >
        Upload File
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { getPriceTemplate, uploadPrice } from '@/api/channels/distributor'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },// eslint-disable-next-line
    selectedProducts: Array,
  },
  data() {
    return {
      downloading: false,
      uploading: false,
      fileData: {},
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
  },
  methods: {
    changeFile(e) {
      const file = e.target.files[0]
      if (file && !['xls','xlsx'].includes(file.name.split('.').pop().toLowerCase())) {
        this.$refs.fileImportEksporRef.value = ''
        this.$message.error('Harap masukkan file xls / xlsx')
        return
      }
      this.fileData = file || {}
    },
    onUploadFile() {
      this.uploading = true
      const { params } = this.$route // , query
      let formdata = new FormData()
      formdata.append('file', this.fileData)
      uploadPrice({
        channel_id: params.id,
        data: formdata,
      })
      .then(res => {
        if (res?.data) {
          this.fileData = {}
          this.$message.success('Template berhasil di upload')
          this.$emit('successUpload')
        }
      })
      .catch(() => {})
      .finally(() => {
        formdata = null
        this.$refs.fileImportEksporRef.value = ''
        this.uploading = false
      })
    },
    onDownloadFile() {
      if (!this.selectedProducts.length || this.selectedProducts.length > 10) {
        this.$modal.info({
          centered: true,
          title: 'Harap pilih rows',
          content: 'minimal 1, maksimal 10',
        })
        return
      }

      this.downloading = true
      const { params } = this.$route
      getPriceTemplate({
        business_id: this.businessId,
        channel_id: params.id,
        params: { product_ids: this.selectedProducts.join(',') },
      })
      .then(res => {
        // console.log('res: ', res)
        if (res?.data) {
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(res.data)
          a.download = 'template.xlsx'
          document.body.appendChild(a)
          a.click()
          setTimeout(() => {
            a.remove()
            // this.$emit('cancel')
          }, 9)
        }
      })
      .catch(() => {})
      .finally(() => { this.downloading = false })
    },
    onCancel() {
      this.uploading = false
      this.fileData = {}
      this.$emit('cancel')
    },
  },
}
</script>

<style lang="scss" scoped>
.pca-alert-info {
  font-size: 12px !important;
  // background-color: #EBF8FF !important;
  // border-color: #2196F3 !important;

  span {
    color: #2196F3 !important;
  }
}

.boxImportExport {
  border: 2px dashed #ccc;
}

.boxImportExport:hover {
  border-color: #f00048;
}
</style>
